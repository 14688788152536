.Portfolio {
  width: 100vw;
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 1550px;
  text-align: center;
}
.PortfolioTitle {
  color: white;
  padding-top: 150px;
  margin: 0;
  font-size: 40px;
}
.PortfolioSubTitle {
  color: white;
  font-weight: 200;
  margin: 0;
  font-size: 25px;
}
.PortfolioItem1 {
  margin-top: 70px;
  /*position: absolute;*/
  /*top: 1400px;*/
  /*left: 50%;*/
  /*margin-left: -100px;*/
}
.PortfolioItem2 {
  padding-top: 100px;
  /*position: absolute;
  top: 2100px;
  left: 50%;
  margin-left: -70px;*/
}
.PortfolioItem3 {
  padding-top: 100px;
  /*position: absolute;
  top: 2500px;
  left: 50%;
  margin-left: -107px;*/
}
.PortfolioItem4 {
  padding-top: 100px;
  /*position: absolute;
  top: 2900px;
  left: 50%;
  margin-left: -190px;*/
}
.PortfolioItem1:hover {
  /*cursor: pointer;*/
}
.PortfolioItem2:hover {
  /*cursor: pointer;*/
}
.PortfolioItem3:hover {
  /*cursor: pointer;*/
}
.PortfolioItem4:hover {
  /*cursor: pointer;*/
}
.CQ {
  height: 150px;
}
.Squirrel {
  height: 150px;
}
.SuperLaundry {
  height: 150px;
}
.SpaceInvaders {
  height: 150px;
}
.PortfolioDescription {
  color: white;
  font-size: 20px;
  margin-top: 0px;
}
.dots {
  color: white;
  text-align: center;
  letter-spacing: 30px;
  margin-top: 820px;
  margin-left: 15px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 30px;
}
.link {
  text-decoration: none;
}
.link:hover {
  cursor: pointer;
  text-decoration: none;
}
/*@media only screen and (max-width: 1100px) {

}
@media only screen and (max-width: 850px) {

}
@media only screen and (max-width: 530px) {

}*/
