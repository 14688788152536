.footerBG {
  text-align: center;
  margin: 0;
  margin-top: 100px;
  padding: 0;
}
.footerContent {
  margin: 0;
  margin-bottom: 5px;
}
.footerLogo {
  height: 70px;
}

@media only screen and (max-width: 750px) {
  .footerBG {
    text-align: center;
    margin-top: 500px;
  }
}
